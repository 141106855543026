import React from "react"
import PropTypes from "prop-types"
import Parser from 'html-react-parser';

class GameGuide extends React.Component {
	constructor(props) {
    super(props); 
    this.refBox = React.createRef();

    this.defaultBoxStyles = {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }

    this.state = {
      arrow: 'middle',
      opened: true,
      index: 0,
      box_styles: this.defaultBoxStyles,
      box_classes: [],
    }
  }

  nextBox = () => {
    var state = this.state;
    state.index += 1;

    if(state.index >= this.props.boxes.length) {
      this.closeGuide();
    } else {
      this.setState(state, this.setBoxStylesForPointerEl);
    }
  }

  closeGuide = () => {
    document.body.classList.remove('noscroll');
    this.setState({opened: false});
  }

  setBoxStylesForPointerEl = () => {
    let desktopBreak = 650;

    var box = this.props.boxes[this.state.index];
    if(window.innerWidth >= desktopBreak && 'pointer' in box) {

      //pointer
      let position = {};
      let pointer_el = document.getElementById(this.props.boxes[this.state.index].pointer);
      if(pointer_el) {
        let el_bounds = pointer_el.getBoundingClientRect();
        let box_styles = {};

        box_styles.top = el_bounds.top+(el_bounds.height/2);
        box_styles.right = el_bounds.width+60;

        //arrow on box
        let box_classes = [];
        if('arrow' in box && box.arrow != false) {
          box_classes.push('__arrow');

          if(box.arrow == 'bottom') {
            box_classes.push('__arrow_bottom');
            box_styles.top -= (this.refBox.current.clientHeight)-45; //-arrow offset

          } else if(box.arrow == 'top') {
            box_classes.push('__arrow_top');
            box_styles.top -= 55;
          } else {
            //middle arrow
            box_styles.top -= (this.refBox.current.clientHeight/2);
          }
        }

        this.setState({box_styles: box_styles, box_classes: box_classes}); 
      } else {
        this.setState({box_styles: this.defaultBoxStyles, box_classes: []});
      }
    } else {
      this.setState({box_styles: this.defaultBoxStyles, box_classes: []});
    }
  }

  componentDidMount() {
    document.body.classList.add('noscroll');
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setBoxStylesForPointerEl();
  }

  render () {
    var box = this.props.boxes[this.state.index];

    return (
      <React.Fragment>
      	<div className={`__game_guide section ${this.state.opened == false ? '__closed' : ''}`}>
          {this.state.opened &&
            <div className={`__box outside-border section-light ${this.state.box_classes.join(" ")}`} style={this.state.box_styles} ref={this.refBox}>
              <div className="bordered long-text centered-el">
                {Parser(box.text)}
                <span className="centered-el"><button className="button" onClick={this.nextBox}>
                  {this.state.index == this.props.boxes.length-1 ? 'Terminer':'Continuer'}
                </button></span>
              </div>
            </div>
          }
      	</div>
      </React.Fragment>
    );
  }
}

GameGuide.propTypes = {
  boxes: PropTypes.array
};

export default GameGuide
