import React from "react"
import PropTypes from "prop-types"
import axios from "axios"

class InvitePlayer extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
    	invInput: 'shareLink',
      inputEmail: '',
      link: this.props.invLink,
      msg: {data: false, type: ''},
    } 
  }


  changeHandler = (e) => {
  	this.setState({
      invInput: e.target.value,
      msg: {data: false, type: ''},
    });
  }

  changeHandlerEmail = (e) => {
    this.setState({inputEmail: e.target.value});
  }

  handleSubmit = (e) => {
    e.preventDefault();
    e.target.disabled= true;
    if(this.state.invInput == 'shareLink') {
      //Copie link to clipboard
      navigator.clipboard.writeText(this.state.link);

      this.setState({msg: {
        data: 'Le lien a été copié!',
        type: 'success'
      }});
      e.target.disabled = false;

    } else if(this.state.invInput == 'sendEmail') {
      //Send email 
      let email = this.state.inputEmail;
      if(this.validateEmail(email)) {
        axios.get(`/api/game/${this.props.gamehash}/${this.props.gift == true ? 'send_gift':'send_invitation'}?email=${email}`).then(res => {
          if(res.status == 200) {
            this.setState({
              inputEmail: '',
              msg: {
                data: `L'invitation a été envoyée à ${email}`,
                type: 'success'
              },
            });
            e.target.disabled = false;
          }
        }).catch( err => {
          this.setState({
            inputEmail: '',
            msg: {
              data: 'Une erreur est survenue.',
              type: 'error'
            },
          });
          e.target.disabled = false;
        })
      } else {
        this.setState({msg: {
          data: 'Le courriel est invalide.',
          type: 'error'
        }});
        e.target.disabled = false;
      }
    }
  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  render () {
    return (
      <React.Fragment>
  			<form className="inv-player">
        	<div className={`inv-choice ${this.state.invInput}`}>
        		<label htmlFor={`inv-shareLink-${this.props.gamehash}`} className="label-shareLink">
          		<input 
	          		type="radio" 
	          		name="inv" 
	          		id={`inv-shareLink-${this.props.gamehash}`}
	          		value="shareLink"
                checked={this.state.invInput == 'shareLink'} 
	          		onChange={this.changeHandler}
          		/>
          		<span className="inline-icon icon-link blue"></span>
              Lien
            </label>
            <label htmlFor={`inv-sendEmail-${this.props.gamehash}`} className="label-sendEmail">
              <input 
                type="radio" 
                name="inv" 
                id={`inv-sendEmail-${this.props.gamehash}`}
                value="sendEmail"
                checked={this.state.invInput == 'sendEmail'} 
                onChange={this.changeHandler}
              />
              <span className="inline-icon icon-mail blue"></span>
              Courriel
            </label>
        	</div> 
          <div className={`inv-fields ${this.state.invInput}`}>
            <input 
              type="text" 
              value={this.state.link}
              readOnly
              className="input-shareLink"
            />
            <input 
              type="email" 
              placeholder="Courriel"
              className="input-sendEmail"
              onChange={this.changeHandlerEmail}
              value={this.state.inputEmail}

            />

            <input 
              type="submit"
              onClick={this.handleSubmit}
            />   
          </div> 
          <p className={`inv-msg ${this.state.msg.type}`}>{this.state.msg.data}</p>
        </form>
      </React.Fragment>
    );
  }
}

InvitePlayer.propTypes = {
  gamehash: PropTypes.string,
  invLink: PropTypes.string,
  gift: PropTypes.bool,
};

export default InvitePlayer
