import React from "react"
import PropTypes from "prop-types"

class InvestigationBtn extends React.Component {
	constructor(props) {
  	super(props);

    this.state = {
      glowing: false
    }
  }

  handleClick = (event) => {
    if(this.props.active) {
      this.props.onInvestigate(this.props.investigation);
    }
  }

  componentDidMount() {
  }

  render () {
    if(this.props.active && this.props.investigation.glowing && !this.props.done) {
      if(this.props.investigation.glowing >= 3) {
        setTimeout(() => {  this.setState({glowing: true}); }, 2*60*1000); //start glowing after 2min
      } else {
        setTimeout(() => {  this.setState({glowing: true}); }, 30*1000); //start glowing after 30sec
      }
    }

    if(this.props.step == 1) {
      var img_scene = this.props.investigation.img_scene_s1;
      var img_scene_hover = this.props.investigation.img_scene_hover_s1;
    } else {
      var img_scene = this.props.investigation.img_scene_s2;
      var img_scene_hover = this.props.investigation.img_scene_hover_s2;
    }

    let styles = {
      height: Math.floor(this.props.investigation.img_height*this.props.displayRatio),
      width: Math.floor(this.props.investigation.img_width*this.props.displayRatio),
      left: this.props.investigation.position_x*this.props.displayRatio,
      top: this.props.investigation.position_y*this.props.displayRatio,
      backgroundImage: `url(${img_scene_hover})`
    }

    let classNam = "";
    if(this.props.active) {
      classNam += " active";

      if(this.props.done) {
        classNam += " investigated";
      }  
    }

    if(this.props.step == 1) {
      var img_scene = this.props.investigation.img_scene_s1;
    } else {
      var img_scene = this.props.investigation.img_scene_s2;
    }
    
    

    return (
      <React.Fragment>            
        <button 
         	type="button"
          className={`single-investigation ${classNam}`}
          style={styles}
          onClick={this.handleClick}
        >
          {this.state.glowing && !this.props.done &&
            <div className="__glowing"></div>
          }

          <img src={img_scene} className = "__img_scene" />
        </button>
      </React.Fragment>
    );
  }
}

InvestigationBtn.propTypes = {
  investigation: PropTypes.object,
  done: PropTypes.bool,
  active: PropTypes.bool,
  displayRatio: PropTypes.number,
  step: PropTypes.number,
};

export default InvestigationBtn
