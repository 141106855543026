import React from "react"
import PropTypes from "prop-types"

class InvestigationNestedBtn extends React.Component {
	constructor(props) {
  	super(props);
  }

  handleClick = (event) => {
    this.props.onInvestigate(this.props.investigation, true, this.props.investigation.transition);
  }

  render () {
    return (
      <React.Fragment>            
        <p className="centered-el">
          <button 
           	type='button'
            className='button blue'
            onClick={this.handleClick}
          >
          {this.props.investigation.action_txt}
          </button>
        </p>
      </React.Fragment>
    );
  }
}

InvestigationNestedBtn.propTypes = {
  investigation: PropTypes.object,
};

export default InvestigationNestedBtn
