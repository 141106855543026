import React from "react"
import PropTypes from "prop-types"
import FunnyVote from "./FunnyVote";
import consumer from "./../../channels/consumer"

class FunnyVotesManager extends React.Component {
	constructor(props) {
    super(props); 

     // preping the results object with the votes kind
    var results = {};
    this.props.votes.forEach(function (v, i) {
    	results[v.kind] = {};
    });

    this.state = {
    	results: results
    };
  }

  countingResults(res) {
  	var results = this.state.results;
    
  	// empty the current votes
		Object.keys(results).forEach(function (k,i) {
			results[k] = {};
		});

  	// and add the new count
  	res.forEach(function (r, i) {
  		results[r.kind][r.vote_player_id] = r.total;
  	});

  	this.setState({results: results});
  }

  componentDidMount() {
  	this.countingResults(this.props.results);

    let thisComponent = this;
    consumer.subscriptions.create({channel: 'FunnyvotesChannel', game: this.props.gamehash}, {
      received(data) {
      	thisComponent.countingResults(JSON.parse(data));
      }
    });
  }

  render () {
    return (
      <div className="votes-list">
      	{this.props.votes.map((v, i) => (
	      	<FunnyVote 
	      		key={i}
	      		title={v.title}
	      		players={this.props.players}
	      		kind={v.kind}
	      		gamehash={this.props.gamehash}
	      		results={this.state.results[v.kind]}
            votes_needed={v.votes_needed}
            already_voted={this.props.already_voted.includes(v.kind)}
	      	/>
	      ))}
      </div>
    );
  }
}

FunnyVotesManager.propTypes = {
  votes: PropTypes.array,
  players: PropTypes.array,
  gamehash: PropTypes.string,
  results: PropTypes.array,
  already_voted: PropTypes.array,
};

export default FunnyVotesManager
