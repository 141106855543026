import React from "react"
import PropTypes from "prop-types"
import Parser from 'html-react-parser';

class ReviewSlider extends React.Component {
	constructor(props) {
    super(props); 

    this.sliderRef = React.createRef();
    this.slidesCount = this.props.reviews.length;

    this.state = {
    	index: 0,
    	slideTranslate: 0
    }
  }

  moveIndex = (i) => {
    var newIndex = this.state.index + i;
    
    if(newIndex <= 0) {
      newIndex = 0;
    } else if(newIndex > this.slidesCount - 1) {
      newIndex = this.slidesCount - 1;
    }

    let slideTranslate = (this.sliderRef.current.clientWidth+38) * newIndex // + slide margin
    this.setState({index : newIndex, slideTranslate: slideTranslate});
  }

  updateWindowDimensions = () => {
    let slideTranslate = (this.sliderRef.current.clientWidth+38) * this.state.index // + slide margin
    this.setState({slideTranslate: slideTranslate});
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  render () {
    return (
      <React.Fragment>
      	<div className="reviews-slider">
	      	<button className="btn prec" 
	      	        onClick={() => this.moveIndex(-1)}
	      	        disabled={this.state.index == 0}
	      	>
	      	  <span className="arrow"></span>Précédant
	      	</button>

	      	<button className="btn next" 
	      	        onClick={() => this.moveIndex(1)}
	      	        disabled={this.state.index >= this.slidesCount -1}
	      	>
	      	  <span className="arrow"></span>Suivant
	      	</button>

	    		{this.props.reviews.map((r, i) => (
	    			<div className="review" key={i} ref={this.sliderRef} style={{transform: `translateX(-${this.state.slideTranslate}px)`}}>
	    				<div className="score centered-el">
	    	        {Parser(r.stars)}
	    	      </div>
	    				<p className="centered-el">{r.desc}</p>

	    				<p className="by">{r.by}</p>
	    			</div>
	    		))}
    		</div>
      </React.Fragment>
    );
  }
}

ReviewSlider.propTypes = {
  reviews: PropTypes.array,
};


export default ReviewSlider
