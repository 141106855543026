import React from "react"
import PropTypes from "prop-types"
import Parser from 'html-react-parser';
import {Howl, Howler} from 'howler';


class GameMenu extends React.Component {
	constructor(props) {
    super(props); 

    // default config
    this.config = {
      journal: false,
      help: false,
      soundCtlr: true, 
      ambience_audio: false,
    }

    // set config passed in props
    if(this.props.config) {
      for (var key of Object.keys(this.config)) {
        if(key in this.props.config) {
          this.config[key] = this.props.config[key]; 
        }
      } 
    } 

    const {Howl, Howler} = require('howler');

    let defaultVolume = 0.2;
    if(this.config.ambience_audio) {
      this.audio = new Howl({
        src: [this.config.ambience_audio],
        loop: true,
        // html5: true,
        volume: defaultVolume,
        // Keep those comments, usefull for debug
        // onplayerror: () => alert('error'),
        // onloaderror: () => alert('load error'),
        // onload: () => alert('load'),
        // onplay: () => alert('playin...'),
      });
    }

    this.state = {
    	opened: false,
      sub_opened: 'subPlayer',
      ambience_playing: false,
      audio_volume: defaultVolume,
    };
  }

  handleClick = (event) => {
  	let tab = event.target.getAttribute("tab");
    let action = event.target.getAttribute("action");

    switch(action) {
      case "toggle":
        if(this.state.opened == tab) {
          document.body.classList.remove('noscroll');
          this.setState({opened: false});  
          
        } else {
          if(tab != 'ambience') {
            document.body.classList.add('noscroll');
          } else {
            // start the music if not playing
            if(this.audio.playing() == false) {
              this.playAmbience();
            }
          }

          this.setState({opened: tab});  
        }
        break;
      case "close":
        if(this.state.opened == tab) {
          document.body.classList.remove('noscroll');
          this.setState({opened: false});  
        }
        break;
      case "close-all":
        document.body.classList.remove('noscroll');
        this.setState({opened: false});
        break;
    }
  }

  handleSubClick = (e) => {
    let tab = e.target.getAttribute('tab');
    if(tab != this.state.sub_opened) {
      this.setState({sub_opened: tab});
    }
  }

  playAmbience = () => {
    this.setState({ambience_playing: true});  
    this.audio.play();
  }

  toggleMuteAmbience = () => {
    if(this.state.audio_volume == 0) {
      this.audio.volume(0.2);
      this.setState({audio_volume: 0.2, ambience_playing: true});
    } else {
      this.audio.volume(0);
      this.setState({audio_volume: 0, ambience_playing: false});
    } 
  }

  setVolume = (e) => {
    let v = event.target.valueAsNumber;  
    let playing = true;

    if(v == 0) {
      playing = false;
    }

    this.audio.volume(v);
    this.setState({audio_volume: v, ambience_playing: playing});
  }

  componentWillUnmount() {
    this.audio.pause();
  }

  

  render () {
    return (
      <React.Fragment>
        <div className={`off-menu ${this.state.opened != false ? '':'hidden'}`} onClick={this.handleClick} action="close-all"></div>
      	<aside className="game-menu">
          <div className="game-menu-tabs">
            {this.config.journal &&
          		<article className={'game-menu-tab section section-light ' + (this.state.opened === 'journal' ? ' opened' : ' closed')}>
                <header>
                  <button 
                    title="Fermer" 
                    className="close-btn" 
                    onClick={this.handleClick} tab="journal" action="close">&#10005;
                  </button>
                  <h2 className="h1 centered big">Journal</h2>
                  <ul className="sub-tab-btn">
                    <li><button className={`player-btn button ${this.state.sub_opened == 'subPlayer' ? 'opened' : ''}`} 
                      title={this.props.playerChar.name} 
                      onClick={this.handleSubClick} tab="subPlayer">
                      {this.props.playerChar.name}
                    </button></li>
                    <li><button className={`players-btn button ${this.state.sub_opened == 'subPlayers' ? 'opened' : ''}`} 
                      title="Collègues" 
                      onClick={this.handleSubClick} tab="subPlayers">
                      Collègues
                    </button></li>
                    <li><button className={`events-btn button ${this.state.sub_opened == 'subEvents' ? 'opened' : ''}`} 
                      title="La Soirée" 
                      onClick={this.handleSubClick} tab="subEvents">
                      La Soirée
                    </button></li>
                  </ul>
                </header>
                <div className={`sub-tab ${this.state.sub_opened == 'subEvents' ? '' : 'hidden' }`}>
                  <div className="content">
                    <div className="long-text">
                      {this.props.textsJournal.map((t, i) => (
                        <div key={i}>
                          <h3 className="h2 big">{t.title}</h3>
                          {Parser(t.text)}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className={`sub-tab ${this.state.sub_opened == 'subPlayer' ? '' : 'hidden' }`}>
                  <div className="content">
                    <h3 className="h2 big">Vous incarnez</h3>
                    <div className="player-character clearfix">
                      <img alt={this.props.playerChar.name} src={this.props.playerChar.img} className="char-img" />
                      <div className="character-details">
                        <h4 className="h3 big" style={{color: `#${this.props.playerChar.color_hex}`}}>
                         {this.props.playerChar.name}
                        </h4>
                        <p>{Parser(this.props.playerChar.attitude)}</p>
                        <div className="char-characteristic">
                          {Parser(this.props.playerChar.characteristic)}
                        </div>
                      </div>
                    </div> 
                    <div className="long-text">
                      {Parser(this.props.playerChar.desc)}
                      <h3 className="h2 big">Récemment au Cabinet</h3>
                      {Parser(this.props.playerChar.history)}
                    </div>
                  </div>
                </div>
                <div className={`sub-tab ${this.state.sub_opened == 'subPlayers' ? '' : 'hidden' }`}>
                  <div className="content">
                    <h3 className="h2 big">Vos collègues</h3>
                    {this.props.relations.map((r, i) => (
                      <div key={i} className="clearfix">
                        <img alt={r.char} src={r.img} className="char-relation-img" />
                        <h4 className="h3 no-spacing" style={{color: `#${r.colorHex}`}}>{r.char}</h4>
                        <span className="played-by">Incarné.e par: {r.player}</span>
                        <div className="long-text">
                          {r.texts.map((t, i2) => (
                            <React.Fragment key={`${i}-${i2}`}>{Parser(t)}</React.Fragment>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <span className="centered-el"><button 
                  title="Fermer" 
                  className="button red" 
                  onClick={this.handleClick} tab="journal" action="close">Fermer
                </button></span>
          		</article>
            }

            {this.config.help &&
          		<article className={'game-menu-tab section section-light ' + (this.state.opened === 'help' ? ' opened' : ' closed')}>
                <header>
                  <button 
                    title="Fermer" 
                    className="close-btn" 
                    onClick={this.handleClick} tab="help" action="close">&#10005;
                  </button>
            			<h2 className="h1 centered big">Aide</h2>
                </header>
                <div className="sub-tab">
                  <div className="content">
                    <div className="long-text">
                      {Parser(this.props.textHelp)}
                    </div>
                  </div>

                  <span className="centered-el"><button 
                    title="Fermer" 
                    className="button red" 
                    onClick={this.handleClick} tab="help" action="close">Fermer</button>
                  </span>

                  <div className="content">
                    <div className="notice">
                      <p className="centered-el">Votre ami.e a eu un problème technique et ne trouve pas la partie?</p>
                      <h2 className="h3 big centered blue">Lien pour la partie</h2>
                      <div className="centered-el">
                        <span className="game-link">{this.props.gameLink}</span>
                      </div>
                    </div>
                  </div>
                </div>
          		</article>
            }
          </div>

          <div  className="menu-list">
            <ul>
              {this.config.soundCtlr && 
                <li>
                  <a 
                    id="MusicBtn"
                    title="Ambiance" 
                    className={`icon-btn icon-music menu-btn 
                                ${this.state.opened === 'ambience' ? 'opened':''}
                                ${this.state.ambience_playing ? 'music-playing':''}
                                `}
                    onClick={this.handleClick} tab="ambience" action="toggle">
                    Ambiance
                  </a>
                  <div className={`ambience-menu ${this.state.opened === 'ambience' ? 'opened' : 'hidden'} ${this.state.ambience_playing ? 'playing':''}`}>
                    <button title="Jouer/Pause" onClick={this.playAmbience} className="sound-btn hidden">Jouer/Pause</button>
                    <button 
                      className={`volume-icon ${this.state.audio_volume == 0 ? 'muted': ''}`} 
                      title="volume" 
                      onClick={this.toggleMuteAmbience}>Volume</button>
                    <input
                      className="volume-slider"
                      type="range"
                      min={0}
                      max={1}
                      step={0.02}
                      value={this.state.audio_volume}
                      onChange={this.setVolume}
                    />
                  </div>
                </li>
              }

              {this.config.help &&
                <li><a 
                  id="HelpBtn"
                  title="Aide" 
                  className={'icon-btn icon-help menu-btn' + (this.state.opened === 'help' ? ' opened':'')}
                  onClick={this.handleClick} tab="help" action="toggle">
                  Aide
                </a></li>
              }

              {this.config.journal &&
                <li><a 
                  id="JournalBtn"
                  title="Journal" 
                  className={'icon-btn icon-book menu-btn' + (this.state.opened === 'journal' ? ' opened':'')}
                  onClick={this.handleClick} tab="journal" action="toggle">
                  Journal
                </a></li>   
              }           
            </ul>
          </div>
      	</aside>

      	
      </React.Fragment>
    );
  }
}

GameMenu.propTypes = {
  textsJournal: PropTypes.array,
  playerChar: PropTypes.object,
  relations: PropTypes.array,
  textHelp: PropTypes.string,
  gameLink: PropTypes.string,
  config: PropTypes.object,
};

export default GameMenu
