import React from "react"
import PropTypes from "prop-types"
import {Howl, Howler} from 'howler'

class NarrationCtrl extends React.Component {
	constructor(props) {
    super(props);

    const {Howl, Howler} = require('howler');

    let defaultVolume = 0.7;
  	this.audio = new Howl({
      src: [this.props.src],
      loop: false,
      volume: defaultVolume,
      // Keep those comments, usefull for debug
      // onplayerror: () => alert('error'),
      // onloaderror: () => alert('load error'),
      // onload: () => alert('load'),
      // onplay: () => alert('playin...'),
    });

    this.state = {
    	playing: false,
      audio_volume: defaultVolume,
    };
  }

  togglePlay = (e) => {
  	e.preventDefault();

  	if(this.audio.playing() == false) {
  		this.setState({playing: true});  
    	this.audio.play();
  	} else {
  		this.setState({playing: false});  
    	this.audio.pause();
  	}
  }

  setVolume = (e) => {
    let v = event.target.valueAsNumber;  
    this.audio.volume(v);
    this.setState({audio_volume: v});
  }

  render () {
    return (
      <React.Fragment>
      	<div className="narration-ctrl bordered border2-dark">
	      	<h2 className="h3 dark">Narration</h2>
	      	<form>
	      		<button onClick={this.togglePlay} className={`btn ${this.state.playing ? 'playing':''}`}>Play</button>
	      		<input
		      	  className="volume-slider"
		      	  type="range"
		      	  min={0}
		      	  max={1}
		      	  step={0.02}
		      	  value={this.state.audio_volume}
		      	  onChange={this.setVolume}
	      		/>
	      	</form>
	      </div>
      </React.Fragment>
    );
  }
}

NarrationCtrl.propTypes = {
  src: PropTypes.string,
};

export default NarrationCtrl
