import React from "react"
import PropTypes from "prop-types"
import Parser from 'html-react-parser';

class CharacterViewer extends React.Component {
	constructor(props) {
    super(props); 

    let genders = ['male', 'female'];
    this.state = {
    	gender: genders[Math.floor(Math.random() * 2)], //random gender
    }
  }

  handleGender = (gender) => {
  	this.setState({gender: gender});
  }

  render () {
  	if(this.state.gender == 'female') {
  		var name = this.props.character.name_f;
  		var attitude = this.props.character.attitude_f;
  	} else {
  		var name = this.props.character.name_m;
  		var attitude = this.props.character.attitude_m;
  	}
    return (
      <div className="char-viewer clearfix">
      	<img alt={this.props.character.lastname} className="char-img" src={this.props.character.img} />
        <div className="gender-choice">
          <button className={`male ${this.state.gender == 'male' ? 'selected':'' }`} title="Homme" onClick={() => this.handleGender('male')}>
            <span className="inline-icon icon-male no-margin bigger red"></span>Homme
          </button>
          <button className={`female ${this.state.gender == 'female' ? 'selected':'' }`} title="Femme" onClick={() => this.handleGender('female')}>
            <span className="inline-icon icon-female no-margin bigger red"></span>Femme
          </button>

          <img className="speaking-level" src={this.props.character.speaking_level} />
        </div>
      	<div className="char-desc">
      		<h2 className="h2 no-spacing" style={{color: `#${this.props.character.color_hex}`}}>
            {name} {this.props.character.lastname}
            <img className="speaking-level-title" src={this.props.character.speaking_level} />
          </h2>
      		<p>{Parser(attitude)}</p>
      	</div>
      </div>
    );
  }
}

CharacterViewer.propTypes = {
	character: PropTypes.object,
};


export default CharacterViewer
