import React from "react"
import PropTypes from "prop-types"
class Piechart extends React.Component {
	constructor(props) {
    super(props);
  }

  render () {

  // 	let slices = [
  // 		{
	 //  		color: "pink",
	 //  		deg: 70,
  // 		},
  // 		{
  // 			color: "lightblue",
  // 			deg: 200,
  // 		},
  // 		{
  // 			color: "green",
  // 			deg: 90,
  // 		}
		// ]

		var conic = "";
		this.props.slices.map((s, i) => {
			if(i != 0) {
				conic += ',';
			}
			conic += `${s.color} 0 ${s.deg}deg`;
		});

    return (
      <React.Fragment>
      <div className="__piechart" style={{backgroundImage: `conic-gradient(${conic})`}}></div>
      </React.Fragment>
    );
  }
}


Piechart.propTypes = {
	slices: PropTypes.array,
}

export default Piechart
