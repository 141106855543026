import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import ReCAPTCHA from "react-google-recaptcha";

class ReviewForm extends React.Component {
	constructor(props) {
    super(props); 

    this.with_recaptcha = false;
    if(this.props.recaptcha) {
      this.with_recaptcha = this.props.recaptcha;
    }
    

    this.state = {
    	formValid: false,
    	username: '',
    	review: '',
      accepted_display: true,
    	score: 0,
      score_over: 0,
     	error: '',
      done: false,
      valid_recaptcha: !this.with_recaptcha,
    }
  }

  changeHandler = (e) => {
  	let nam = e.target.name;
    let val = e.target.value;

    if(nam == 'accepted_display') {  
      val = e.target.checked;
    }

    let state = this.state;
    state[nam] = val;
    state['formValid'] = this.validateStateForm();
    state['error'] = this.form_error;
    this.setState(state);
  }

  onRecaptcha = (v) => {
    let state = this.state;

    if(v != null) {
      state['valid_recaptcha'] = true;
    } else {
      state['valid_recaptcha'] = false;
    }
    
    state['formValid'] = this.validateStateForm();
    state['error'] = this.form_error;
    this.setState(state);
  }

  starHover = (e) => {
    this.setState({score_over: e.target.dataset.score});
  }

  starHoverOut = (e) => {
    this.setState({score_over: 0});
  }

  validateStateForm() {
  	if(this.state.username.trim() == '') {
  		this.form_error = 'Veuillez écrire votre nom.'
  		return false;
    } else if (this.state.username.length > 30) {
      this.form_error = 'Votre nom est trop long.'
      return false;
  	} else if(this.state.score == 0) {
  		this.form_error = 'Veuillez donner des étoiles.'
  		return false;
  	} else if (this.state.review.trim() == '') {
  		this.form_error = 'Veuillez écrire un commentaire.'
  		return false;
  	} else if (this.state.review.length > 250) {
      this.form_error = 'Votre commentaire est trop long.'
      return false;
    } else if (this.with_recaptcha && !this.state.valid_recaptcha) {
      this.form_error = 'Validez que vous n\'êtes pas un robot.'
      return false;
    } else {
  		this.form_error = '';
  		return true;
  	}
  }

  handleSubmit = (e) => {
    e.target.disabled = true;
    e.target.classList.add('loading');
    
    e.preventDefault();

    var bodyFormData = new FormData();
    bodyFormData.append('authenticity_token', this.props.authenticity_token);
    bodyFormData.append('game', this.props.game);
    bodyFormData.append('username', this.state.username);
    bodyFormData.append('score', this.state.score);
    bodyFormData.append('review', this.state.review);
    bodyFormData.append('accepted_display', this.state.accepted_display);
    

    axios({
      method: "post",
      url: "/api/new_review",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        this.setState({
          username: '',
          review: '',
          accepted_display: true,
          score: 0,
          done: true,
        })
      })
      .catch((res) => {
        //handle error
        this.setState({error: 'Avis invalide.', formValid: false});
      });


  }

  render () {
    if(this.state.done == false ) {
      return (
        <React.Fragment>
        	<form method='post' className='form form-review' onSubmit={e => e.preventDefault()}>
        	  <input type='hidden' name='authenticity_token' value={this.props.authenticity_token} />
        	  <div className="section section-light">
  		    		<div className="content">
  				    	<h2 className="h2">Laisser un avis</h2>
  				    	<div className="inner-form">

  					    	<div className="field field-username">
  						      <label htmlFor='username'>Votre nom</label>
  						      <input
  						        type='text'
  						        autoComplete='off'
  						        name='username'
  						        id='username'
  						        maxLength='40'
  						        value={this.state.username}
  						        onChange={this.changeHandler}
  						    	/>
  						    </div>

  						    <div className="field field-score">
  						      <div onChange={this.changeHandler}>
  				            <label className="container-score">
                        <input type="radio" value="1" name="score" />
                          <span 
                            className={`star ${this.state.score_over >= 1 ||
                                            (this.state.score_over == 0 && this.state.score >= 1) ? 'full':
                                            this.state.score_over != 0 ? 'empty':'' }`
                                      }
                            data-score="1" onMouseOver={this.starHover} onMouseOut={this.starHoverOut} >
                          </span>1
                        </label>
  				            <label className="container-score">
                        <input type="radio" value="2" name="score" />
                          <span 
                            className={`star ${this.state.score_over >= 2 ||
                                            (this.state.score_over == 0 && this.state.score >= 2) ? 'full':
                                            this.state.score_over != 0 ? 'empty':'' }`
                                      }
                            data-score="2" onMouseOver={this.starHover} onMouseOut={this.starHoverOut} >
                          </span>2
                        </label>
  				            <label className="container-score">
                        <input type="radio" value="3" name="score" />
                          <span 
                            className={`star ${this.state.score_over >= 3 ||
                                            (this.state.score_over == 0 && this.state.score >= 3) ? 'full':
                                            this.state.score_over != 0 ? 'empty':'' }`
                                      }
                            data-score="3" onMouseOver={this.starHover} onMouseOut={this.starHoverOut} >
                          </span>3
                        </label>
  				            <label className="container-score">
                        <input type="radio" value="4" name="score" />
                          <span 
                            className={`star ${this.state.score_over >= 4 ||
                                            (this.state.score_over == 0 && this.state.score >= 4) ? 'full':
                                            this.state.score_over != 0 ? 'empty':'' }`
                                      }
                            data-score="4" onMouseOver={this.starHover} onMouseOut={this.starHoverOut} >
                          </span>4
                        </label>
  				            <label className="container-score">
                        <input type="radio" value="5" name="score" />
                          <span 
                            className={`star ${this.state.score_over >= 5 ||
                                            (this.state.score_over == 0 && this.state.score >= 5) ? 'full':
                                            this.state.score_over != 0 ? 'empty':'' }`
                                      }
                            data-score="5" onMouseOver={this.starHover} onMouseOut={this.starHoverOut} >
                          </span>5
                        </label>
  				          </div>
                  </div>

                  <div className="field field-review">
                    <label className="hidden" htmlFor='review'>Avis</label>
  							    <textarea
  							    	name='review'
                      placeholder='Écrire un commentaire'
  		    	          value={this.state.review}
  		    	          onChange={this.changeHandler}
  		    	          rows={5}
  		    	          cols={5}
  		    	        />
  		    	      </div>
                  <div className="field">
                    <input 
                      type="checkbox" 
                      className="small-checkbox" 
                      name="accepted_display" 
                      id="accepted_display"
                      onChange={this.changeHandler}
                      checked={this.state.accepted_display}
                    />
                    <label className="small-checkbox-label" htmlFor="accepted_display">J'accepte que mon commentaire soit publié.</label>
                  </div>

                  {this.with_recaptcha &&
                    <div className="field">
                      <div className="g-recaptcha">
                        <ReCAPTCHA
                          sitekey={this.props.recaptcha_key}
                          onChange={this.onRecaptcha}
                        />
                      </div>
                    </div>
                  }

     				    	{this.state.error != ''? <p className="error char-error centered">{this.state.error}</p> : '' }
     				    	<input
     				    		type='submit'
     				    		value='Soumettre'
     				    		className="button submit"
     				    		disabled={!this.state.formValid} 
                    onClick={this.handleSubmit}
     				    	/>
  						  </div>
  						</div>
  					</div>
        	</form>
        </React.Fragment>
      );
    } else {
      return (
        <div className="section section-light">
          <div className="content review-done">
            <h2 className="h2 centered">Laisser un avis</h2>
              <span className="icon"></span>
              <p className="centered-el bigger">Merci pour votre avis!</p>
          </div>
        </div>
      );
    }
  }
}

ReviewForm.propTypes = {
  game: PropTypes.string,
  authenticity_token: PropTypes.string,
  recaptcha: PropTypes.bool,
  recaptcha_key: PropTypes.string,
};

export default ReviewForm
