import React from "react"
import PropTypes from "prop-types"
class FbShareFeed extends React.Component {
	constructor(props) {
    super(props);
  }

  handleClick = (e) => {
  	FB.ui({
  	  method: 'feed',
  	  link: this.props.link,
  	}, function(response){});
  }

  render () {
    return (
      <React.Fragment>
      	<a className="link share-fb" onClick={this.handleClick}>
      		<span className="inline-icon icon-fb"></span>
      		Partager
    		</a>
      </React.Fragment>
    );
  }
}

FbShareFeed.propTypes = {
	link: PropTypes.string,
}


export default FbShareFeed
