import React from "react"
import PropTypes from "prop-types"
import Parser from 'html-react-parser'

class RevealBtn extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
    	revealed : false,
    }
  }

  handleClick = (e) => {
  	this.setState({revealed: true});
  } 

  render () {
    return (
      <React.Fragment>
	      {!this.state.revealed ? (
	      	<div className="centered-el">
		      	<button
			      	title={this.props.btn}
			      	className="button darkred bigger"
			      	onClick={this.handleClick}
			      >
			      	{this.props.btn}
		      	</button>
		      </div>
	      ) : (
	      	<React.Fragment>
		      	<h3 className="h2 dark big centered">{this.props.title}</h3>
		      	<div className="long-text">
		      		{Parser(this.props.text)}
		      	</div>
		      </React.Fragment>
	      )}
      </React.Fragment>
    );
  }
}

RevealBtn.propTypes = {
	btn: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
}

export default RevealBtn
