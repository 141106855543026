import React from "react"
import PropTypes from "prop-types"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import InvestigationBtn from "./InvestigationBtn";
import InvestigationNestedBtn from "./InvestigationNestedBtn";
import ShowInvestigationToBtn from "./ShowInvestigationToBtn";
import axios from "axios"
import consumer from "./../../../channels/consumer"
import Parser from 'html-react-parser';
import Checkbox from "./../../utilities/Checkbox"

class Investigation extends React.Component {
	constructor(props) {
  	super(props);

    this.preloadedImages = [];
    this.refCard = React.createRef();

    this.state = {
      loaded: false,
      investigation_step: this.props.investigation_step,
      done_investigations: this.props.done_investigations,
      show_inspect: false,
      ready_to_vote: this.props.ready_to_vote,
      investigation: {
        title: '',
        desc: '',
        details: [],
        nesteds: [],
      },
      wrapper_size_pos: {
        height: this.props.img_height, //heihgt of the wrapper
        width: this.props.img_width, //width of thw wrapper
        right: 0, //offset to the right to center wrapper on screen
      },
      displayRatio: 1,
      posX: false,
      light_flash: false,
      show_help: false,
      scrollOptionsPos: 0,
      investigation_title_above: false,
      prompt_investigation: false,
    }

    this.show_timed_investigation = false;

    this.promptInterval = false;

    if(this.props.investigation_step == 1) {
      this.helpInterval = setInterval(this.showHelp, 2*60*1000); //every 2min
    }
  }

  preloadImages = () => {
    // first load the loader img.
    this.loadImage(this.props.loader);  

    // background imgs
    for (var key in this.props.imgs_step) {
      if(key == "foreground") {
        for (var key2 in this.props.imgs_step["foreground"]) {
          this.loadImage(this.props.imgs_step["foreground"][key2]);  
        }
      } else {
        this.loadImage(this.props.imgs_step[key]);
      }
    }

    // imgs investigation
    var inves_imgs = ['img_scene_hover_s1', 'img_scene_hover_s2', 'img_scene_s1', 'img_scene_s2', 'img_large'];
    this.props.investigations.map( inves => {
      inves_imgs.map( s => {
        this.loadImage(inves[s]);
      });
    });
  }

  loadImage = (src) => {
    const newImage = new Image();
    newImage.src = src;
    this.preloadedImages.push(newImage);
  }

  updateInvestigationFromWS = (data) => {
    let jData = JSON.parse(data);
    if(jData.step && jData.step != this.state.investigation_step) {
      if(jData.step == 2 && this.state.investigation_step == 1) {
        clearInterval(this.helpInterval);
        this.closeInspect();
        this.setState({light_flash: true, show_help: false});

        setTimeout(() => {
          this.setState({investigation_step: jData.step});
        }, 2000);

      } else {
        this.setState({investigation_step: jData.step});
      }
    }
  }

  handleInvestigate = (investigation, nested=false, nested_transition=true) => {       
    //  check if already done

    if(nested) {
      this.setState({
        investigation: investigation,
        investigation_title_above: true,
        show_inspect: !nested_transition,
      }); 

      if(nested_transition) {
        var _this = this;
        setTimeout(function () {
          document.body.classList.add('noscroll');
          _this.setState({show_inspect: true});
        }, 700);
      }

      this.refCard.current.scrollTop = 0;

    } else {
      if(this.state.done_investigations.includes(investigation.id)) {
        document.body.classList.add('noscroll');
        this.setState({
          show_inspect: true,
          investigation: investigation,
        }, () => this.stopBrowserBack(this.closeInspect));

        this.refCard.current.scrollTop = 0;

      } else {
        axios.get(`/api/game/${this.props.gamehash}/investigation?add=${investigation.id}`).then(res => {
          if(res.status == 200) {       
          } else {
            console.log(`[Error] ${res.data} (${res.status})`);
          }
        })

        let new_done = this.state.done_investigations;
        new_done.push(investigation.id);
        document.body.classList.add('noscroll');
        this.setState({
          done_investigations: new_done,
          investigation: investigation,
          show_inspect: true,
        }, () => this.stopBrowserBack(this.closeInspect));      
      }      
    }      
  }

  closeInspect = () => {
    document.body.classList.remove('noscroll');
    this.setState({show_inspect: false, investigation_title_above: false}, this.startBrowserBack);

    if(this.show_timed_investigation) {
      this.show_timed_investigation = false;
      var _this = this;
      setTimeout(function () {
        document.body.classList.add('noscroll');
        _this.setState({
          show_inspect: true,
          investigation: _this.props.timed_investigation,
        }, () => _this.stopBrowserBack(_this.closeInspect));

        _this.refCard.current.scrollTop = 0;

      }, 700);
    }
  }

  showInvestigationTo = (investigation_id, player_id) => {
    axios.get(`/api/game/${this.props.gamehash}/show_investigation?i=${investigation_id}&to=${player_id}`).then(res => {
      if(res.status == 200) {       
      } else {
        console.log(`[Error] ${res.data} (${res.status})`);
      }
    })
  }

  handlePromptInvestigation = () => {
    let inves = this.props.investigations.find(i => i.id == this.state.prompt_investigation);

    if(inves) {
      this.handleInvestigate(inves);
    }

    clearInterval(this.promptInterval);
    this.setState({prompt_investigation: false})
  }


  receiveInvestigation = (investigation_id) => {
    clearInterval(this.promptInterval);
    this.promptInterval = setInterval(() => {this.setState({prompt_investigation: false})}, 60*1000); //60 sec

    this.setState({prompt_investigation: investigation_id})
  }

  // handle back button to close inspect
  stopBrowserBack = (callback) => {
    window.history.pushState(null, "", window.location.href);

    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href);
      callback();
    };
  }

  startBrowserBack = () => {
    window.onpopstate = undefined;
  }

  //on resize of the screen, fix the investigation wrapper
  updateWindowDimensions = () => {
    var screenW = document.documentElement.clientWidth;
    var screenH = document.documentElement.clientHeight;
    var state = this.state;
    var imgW = this.props.img_width
    var imgH = this.props.img_height
    var displayRatio = 1;

    //if image heigth is larger than the screen, makes it the same heigth
    if(screenH < imgH) {
      displayRatio = screenH/imgH; //scale displayRatio
      imgW = imgW*displayRatio;
      imgH = imgH*displayRatio;
    }

    var wrapperH = screenH;

    //calculate wrapper width based on screen width
    if(screenW >= imgW) {
      var wrapperW = imgW;
      var wrapperPos = 0;
    } else {
      var wrapperW = imgW*2-screenW;
      var wrapperPos = imgW-screenW;
    }

    state.displayRatio = displayRatio;
    state.imgW = imgW;
    state.imgH = imgH;
    state.wrapper_size_pos.width = wrapperW;
    state.wrapper_size_pos.height = wrapperH;
    state.wrapper_size_pos.right = wrapperPos;
    state.show_help = false;


    this.setState(state);
  }

  componentDidMount() {
    this.updateWindowDimensions(); //run it once to set the wrapper sizes
    
    window.addEventListener('resize', this.updateWindowDimensions);

    //sub to WS to received update on investigation step update
    consumer.subscriptions.create({
      channel: 'InvestigationChannel',
      game: this.props.gamehash
    }).received = data => {
      this.updateInvestigationFromWS(data);
    }

    consumer.subscriptions.create({
      channel: 'ShowInvestigationChannel',
      game: this.props.gamehash,
      character: this.props.character, 
    }).received = data => {
      this.receiveInvestigation(data);
    }        

    this.preloadImages();

    //if document not loader yet, add the listener
    if (document.readyState === "complete") {
      this.handleLoad();
    } else {
      window.addEventListener('load', this.handleLoad);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('load', this.handleLoad);
  }

  handleLoad = () => {
    // setting a timeout to make sure everything is rendred
    setTimeout(() => {
      this.setState({loaded: true});

      if(Object.entries(this.props.timed_investigation).length != 0) {
        setTimeout(() => {this.show_timed_investigation = true}, this.props.timed_investigation.time);
      }

      if(this.state.investigation_step == 1 && Object.entries(this.props.initial_investigation).length != 0) {
        document.body.classList.add('noscroll');
        this.setState({
          show_inspect: true,
          investigation: this.props.initial_investigation
        }, () => this.stopBrowserBack(this.closeInspect));
      }
    }, 1000);
  }

  readyToVote = (e) => {
    let ready = e.target.checked
    axios.get(`/api/game/${this.props.gamehash}/players_list?ready=${ready}`).then(res => {
      if(res.status == 200) {
        this.setState({ready_to_vote: ready});
      }
    }).catch( err => {
      console.log(`[Error] ${err}`);
    })
  }

  limitPan = (e) => {
    // custom pan limi tto wrapper since it wasn't working properly on firefox
    var posX = e.positionX;
    if(posX < 0) {
      this.setState({posX: 0});
    } else if (posX > this.state.wrapper_size_pos.right) {
      this.setState({posX: this.state.wrapper_size_pos.right});
    } else {
      // save it but no re-render
      this.state.posX = posX;
    }
  }

  showHelp = () => {
    if(this.state.imgW-50 > document.documentElement.clientWidth) { //-50 avoids it showing if side-scroll is of no relevant pixels
      this.setState({show_help: true});
    }
  }

  hideHelp = () => {
    this.setState({show_help: false});

    clearInterval(this.helpInterval);
    this.helpInterval = setInterval(this.showHelp, 2*60*1000); //every 2min
  }

  scrollCard = (i) => {
    this.refCard.current.scroll({top: this.refCard.current.scrollTop+i, left: 0, behavior: 'smooth' });
  }

  onScrollCard = () => {
    this.setState({scrollOptionsPos: this.refCard.current.scrollTop});
  }

  render () {
    // react-zoom-pan-pinch config
  	var wrapper_options = {
  		limitToBounds: false,
      limitToWrapper: false,
      maxScale: 1,
      disabled: false,
      wrapperClass: "wrapper-investigation"
  	}

    if(this.state.show_inspect == true) {
      wrapper_options.disabled = true;
    }

  	var wheel_props = {
  		disabled: true,
  		wheelEnabled: false,
  	}

    var pan_props = {
      padding: true,
      lockAxisY: true,
    }
    var zoomIn_props = {
      disabled: true,
      step: 5,
    }

    var doubleClick_props = {
      disabled: true,
      step: 5,
    }

    //img translation to center it in wrapper (weirdly 0 seems bug so 1 is top-left)
    var imgCenteredPos = 1;
    var screenW = document.documentElement.clientWidth;
    var screenH = document.documentElement.clientHeight;
    var imgW = this.props.img_width
    var imgH = this.props.img_height

    //if image heigth is larger than the screen, makes it the same heigth
    if(screenH < imgH) {
      let displayRatio = screenH/imgH; //scale displayRatio
      imgW = imgW*displayRatio;
      if(this.state.posX === false && imgW > screenW) {
        imgCenteredPos = imgW/2-screenW/2
        this.state.posX = imgCenteredPos;
      }
    }

    //set the img based on current step
    let checking_step = 1;
    var img_step_src = "";
    var img_foreground_step_src = "";
    while (checking_step <= this.state.investigation_step) {
      if(checking_step in this.props.imgs_step) {
        img_step_src = this.props.imgs_step[checking_step];
        if(this.props.imgs_step["foreground"] && Object.keys(this.props.imgs_step["foreground"]).length != 0) {
          let imgs_step_forground = this.props.imgs_step["foreground"];
          img_foreground_step_src = imgs_step_forground[checking_step];
        }
      }

      checking_step++;
    }

    return (
    	<React.Fragment>
        <div className={`investigation-loading ${this.state.loaded ? 'closed' : ''}`}>
          <img alt='loading' src={this.props.loader} />
        </div>

    		<div className={`investigation-overlay ${this.state.show_inspect ? 'opened' : 'closed'}`}>
          <div className={`off-investigation ${this.state.investigation.hide_close == true?'hidden':''}`} onClick={this.closeInspect}></div>
          <div className="investigation-card" ref={this.refCard} onScroll={this.onScrollCard}>
            <div className="scroll-options" style={{marginTop: this.state.scrollOptionsPos}}>
              <button className="up" onClick={() => this.scrollCard(-100)}><span className="arrow"></span>Haut</button>
              <button className="down" onClick={() => this.scrollCard(100)}><span className="arrow"></span>Bas</button>
            </div>
            <div className="outside-border section-light">
              <div className="bordered border-dark">
                {this.state.investigation_title_above && 
                  <h2 className={`h3 big centered ${this.state.investigation.title_class}`}>{this.state.investigation.title}</h2>
                }
                {this.state.investigation.img_large &&
                  <img alt={this.state.investigation.title} src={this.state.investigation.img_large} className="card-img" />
                }
                {!this.state.investigation_title_above && 
                  <h2 className={`h3 big centered ${this.state.investigation.title_class}`}>{this.state.investigation.title}</h2>
                }
      	      	
                <div className="long-text">
        	      	{Parser(this.state.investigation.desc)}
                  {this.state.investigation.nesteds && this.state.investigation.nesteds.map((nested, i) => (
                    <InvestigationNestedBtn 
                      key={i} 
                      investigation={nested}
                      onInvestigate={this.handleInvestigate}
                    /> 
                  ))}
                  {this.state.investigation.details && this.state.investigation.details.map((detail, i) => (
                    <div key={i} style={{border: `2px solid #${this.props.character_color}`, padding: 21, marginBottom: 26}}>
                      <span className="centered-el" style={{color: `#${this.props.character_color}`}}>Information personnelle</span>
                      {Parser(detail.desc)}
                      {'about_id' in detail &&
                        <ShowInvestigationToBtn 
                          showInvestigationTo={this.showInvestigationTo}
                          label={`Montrer à <span style="color: #${detail.about_color}">${detail.about_name}</span>`}
                          character={detail.about_id}
                          investigation={this.state.investigation.id}
                        />
                      }
                    </div>
                  ))}
                </div>

                <span className={`centered-el ${this.state.investigation.hide_close == true?'hidden':''}`}><button 
                  title="Fermer" 
                  className="button red" 
                  onClick={this.closeInspect}>Fermer</button>
                </span>
              </div>
            </div>
          </div>
	      </div>

        {this.state.prompt_investigation != false &&
          <button onClick={this.handlePromptInvestigation} className="prompt-inves-btn">Voir investigation</button>
        }

	      <TransformWrapper
	        defaultScale={1}
          positionX={this.state.posX} 
          defaultPositionY={1} 
	        options={wrapper_options}
	        wheel={wheel_props}
          pan={pan_props}
          zoomIn={zoomIn_props} 
          doubleClick={doubleClick_props} 
          onPanning={this.limitPan}
          onPanningStart={this.hideHelp}
	      >
	        {({ zoomIn, zoomOut, ...rest}) => (
	          <React.Fragment>
	            <div className="tools">
                {this.state.show_help &&
                  <React.Fragment>
                    <span className="__side_scroll_arrow __right"></span>
                    <span className="__side_scroll_arrow __left"></span>
                  </React.Fragment>
                }
	            </div>
              <div className={`ready-to-vote ${this.state.investigation_step < 4 ? 'disabled':''}`}>
                <form>
                  <label htmlFor="ready" className="label__checkbox"> 
                    <Checkbox 
                      type="checkbox" 
                      id="ready" 
                      name="ready" 
                      value="ready" 
                      checked={this.state.ready_to_vote}
                      onChange={this.readyToVote}
                    />
                    Passer au Vote
                  </label>
                </form>
              </div>
	            <TransformComponent>
	              <img 
                  src={img_step_src} 
                  alt="Investigation" 
                  className="background" 
                  style={{
                    width: (this.props.img_width*this.state.displayRatio), 
                    height: (this.props.img_height*this.state.displayRatio)
                  }}
                  />
                {img_foreground_step_src != "" &&
                  <div className="investigation-foreground" 
                    style={{backgroundImage: `url(${img_foreground_step_src})`,
                            width: (this.props.img_width*this.state.displayRatio), 
                            height: (this.props.img_height*this.state.displayRatio)}}>
                  </div>
                }

                {this.state.light_flash &&
                  <div className="__light-flash"></div>
                }

	          		{this.props.investigations.map((inves, i) => (
	          			<InvestigationBtn 
	          				key={i} 
	          				investigation={inves}
	          				onInvestigate={this.handleInvestigate}
                    active={inves.step <= this.state.investigation_step}
                    done={this.state.done_investigations.includes(inves.id)}
                    displayRatio={this.state.displayRatio}
                    step={this.state.investigation_step}
          				/> 
	          		))}
	            </TransformComponent>
	          </React.Fragment>
	        )}
	      </TransformWrapper>
        <style>
          {
            `.wrapper-investigation {
              height: ${this.state.wrapper_size_pos.height}px !important;
              width: ${this.state.wrapper_size_pos.width}px !important;
              right: ${this.state.wrapper_size_pos.right}px !important;
            }`
          }
        </style>
	    </React.Fragment>
    );
  }
}

Investigation.propTypes = {
  imgs_step: PropTypes.object,
  gamehash: PropTypes.string,
  character: PropTypes.number,
  investigations: PropTypes.array,
  investigation_step: PropTypes.number,
  done_investigations: PropTypes.array,
  img_width: PropTypes.number,
  img_height: PropTypes.number,
  loader: PropTypes.string,
  ready_to_vote: PropTypes.bool,
  initial_investigation: PropTypes.object,
  character_color: PropTypes.string,
  timed_investigation: PropTypes.object,
};

export default Investigation
