import React from "react"
import PropTypes from "prop-types"

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
  }

  changeHandler = (event) => {
    this.props.onChange(event);
  }

  render() { 
    let classNam = "__checkbox"
    if(this.props.checked) {
      classNam += " checked"
    }

    return (
      <span className={classNam}>
        <input
          className="input__checkbox"
          type="checkbox"
          id={this.props.id}
          name={this.props.name}
          value={this.props.value} 
          onChange={this.changeHandler}
          checked={this.props.checked}
        /> 
        <span className="icon__checkbox"></span>
      </span>
    ); 
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool,  
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default Checkbox
