import React from "react"
import PropTypes from "prop-types"
import consumer from "./../../channels/consumer"

class GameStateWatcher extends React.Component {
  constructor(props) {
    super(props);

    if(this.props.in == false) {
      this.state = {
        current: 'done',
      }
    } else {
      this.state = {
        current: 'show',
      }
    }
  }

  handleLoad = () => {
    this.stopBrowserBack();

    this.setState({current: 'in'})
    var _this = this;
    setTimeout(function () {
      _this.setState({current: 'done'});
    }, 1100);
  }

  goToNextPage = (page) => {
    if(!page.endsWith(this.props.on_page)) {
      this.setState({current: 'out'})

      var _this = this;
      setTimeout(function () {
        window.location.replace(page);
      }, 1100);
    }
  }

  stopBrowserBack = () => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href);
    };
  }

  componentDidMount() {
    var _this = this;
    consumer.subscriptions.create({channel: 'GswatcherChannel', game: this.props.gamehash}, {
      received(data) {
        _this.goToNextPage(data)
      }
    });

    //if document not loader yet, add the listener
    if (document.readyState === "complete") {
      this.handleLoad();
    } else {
      window.addEventListener('load', this.handleLoad);
    }
  }

  render() { 
    return (
      <div className={`__page_transition ${this.state.current}`}></div>
    ) 
	}
}

GameStateWatcher.propTypes = {
  gamehash: PropTypes.string,
  on_page: PropTypes.string,
  in: PropTypes.bool,  
};

export default GameStateWatcher
