import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import Parser from 'html-react-parser'

class JoinGameForm extends React.Component {
	constructor(props) {
    super(props);

    // those should match the css breaks at all time ! 
    this.two_col_break = 400;
    this.three_col_break = 650;

    this.form_error = '';

    this.state = {
    	screenW: document.documentElement.clientWidth,
      username: '',
      gender: 'female',
      character: -1,
      formValid: false,
     	error: '',
    };
  }

  changeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;

    let state = this.state;
    state[nam] = val;
    state['formValid'] = this.validateStateForm();
    state['error'] = this.form_error;
    this.setState(state);
  }

  validateStateForm() {
  	if(this.state.username.trim() == "") {
  		this.form_error = 'Veuillez écrire votre nom.'
  		return false;
  	} else if(this.state.gender == "") {
  		this.form_error = 'Veuillez chosir un genre de personnage.'
  		return false;
  	} else if (this.state.character == -1) {
  		this.form_error = 'Choisissez un personnage pour joindre la partie.'
  		return false;
  	} else {
  		this.form_error = '';
  		return true;
  	}
  }

  updateWindowDimensions = () => {
  	this.setState({screenW: document.documentElement.clientWidth});
  }

  disableSubmit= () => {
    this.setState({formValid: this.validateStateForm(), error: this.form_error});
  }


  componentDidMount() {
    this.disableSubmit();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

   componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  handleSubmit = (e) => {
    e.target.disabled = true;
    e.target.classList.add('loading');

  	if(this.validateStateForm()) {
  		var params = {
  			char: this.props.characters[this.state.character].id,
  			gender: this.state.gender,
  			username: this.state.username,
  		}

  		var req = `/api/game/${this.props.gamehash}/join`;

  		//add params
  		Object.keys(params).map((key, i) => {
  			if(i === 0) {
  				req += '?';
  			} else {
  				req += '&';
  			}

  			req += `${key}=${params[key]}`
  		});

  		axios.get(req).then(res => {
  		  if(res.status == 200) {
  		  	//since the the player will be added the backend will redirect to the right step
  		    location.reload();
  		  } else {
  		  	this.setState({error: res.data});
          e.target.disabled = false;
          e.target.classList.remove('loading');
  		  }
  		}).catch( err => {
        if(err.response.status == 400) {
          this.setState({error: err.response.data});
        } else {
          this.setState({error: 'Une erreur est survenue.'});
        }
        e.target.disabled = false;
        e.target.classList.remove('loading');
      })
  	}
  }

  render () {
  	var info_after = -1; //default -1 if no char selected yet
  	var char_pos = parseInt(this.state.character);
  	var chosen_char = this.props.characters[this.state.character]

  	// calculates after which <li> the info box needs to appear base on the screen width
  	if(char_pos != -1) {
  		if(this.state.screenW < this.two_col_break) {
  			// one col
  			info_after = char_pos;
  		} else if(this.state.screenW < this.three_col_break) {
  			//two col
  			//remember, the first char pos is 0, so I add +1 the make the % simple for my simple mind
  			if((char_pos+1) % 2 === 0 || char_pos == this.props.characters.length){
  				//pair
  				info_after = char_pos;
  			} else {
  				//odd
  				info_after = char_pos+1;
  			}
  			
  		} else {
  			//three col

  			// makes the subsequent logic works line by line, with a reference of 3
  			let line_char_pos = char_pos - (Math.floor(char_pos/3)*3);

  			//remember, the first char pos is 0, so I add +1 the make the % simple for my simple mind
  			if((line_char_pos+1) % 3 === 0 || char_pos == this.props.characters.length) {
  				//third or last
  				info_after = char_pos;
  			} else if((line_char_pos+1) % 2 === 0) {
  				//second
  				info_after = char_pos+1;
  			} else {
  				//first
  				info_after = char_pos+2;
  			}
  		}
  	}

    return (
    	<React.Fragment>
	    <form className="form join-form" onSubmit={e => e.preventDefault()}>
	    	<div className="section section-light">
	    		<div className="content">
			    	<h2 className="h2 big">Joindre la partie</h2>
			    	<div className="inner-form">
				    	<div className="field">
					      <label htmlFor='username'>Votre nom</label>
					      <input
					        type='text'
					        autoComplete='off'
					        name='username'
					        id='username'
					        maxLength='40'
					        value={this.state.username}
					        onChange={this.changeHandler}
					    	/>
					    </div>

					    <div className="field">
						    <p className="label centered-el">Je désire jouer un personnage</p>
						    <div className={`gender-choice ${this.state.gender}`}>
			        		<label htmlFor="female" className="label-female">
			          		<input 
				          		type="radio" 
				          		name="gender" 
				          		id="female" 
				          		value="female"
			                checked={this.state.gender == 'female'} 
				          		onChange={this.changeHandler}
			          		/>
			              Femme
			            </label>
			            <label htmlFor="male" className="label-male">
			              <input 
			                type="radio" 
			                name="gender" 
			                id="male" 
			                value="male"
			                checked={this.state.gender == 'male'} 
			                onChange={this.changeHandler}
			              />
			              Homme
			            </label>
						    </div>
						  </div>
			    	</div>

			    	<div className="character-choice clearfix">
			    		<h3 className="char-choice-title">Choix du personnage</h3>
			    		<ul>
			    		{this.props.characters.map((char, i) => (
			    			<div key={i}>
				    			<li className={this.props.pickedChars.includes(char.id)? 'picked':''}>
                    {this.props.pickedChars.includes(char.id) && 
                      <span className="picked-notice">Déjà Sélectionné</span>
                    }
							    	<label 
							    		className={this.state.character == i ? 'selected':''}
							    		htmlFor={`char${i}`} 
							    		style={{backgroundImage: `url(${char.img_grey_src})`}}
						    		>
							    		<input 
							    			type='radio' 
							    			name='character' 
							    			id={`char${i}`}
							    			value={i}
							    			checked={this.state.character == i}
							    			onChange={this.changeHandler}
							    			disabled={this.props.pickedChars.includes(char.id)}
						    			/>
						    			{<img alt={char.lastname} src={`${char.img_src}`} />}
				    					{this.state.gender === 'female' ? char.name_f : char.name_m} {char.lastname}
				    				</label>
				    			</li>
				    			{info_after == i &&
				    				<div className="char-info">
				    					<div className="bordered border-dark">

				    						<h4 className="h3" style={{color: `#${chosen_char.color_hex}`}}>
                          {this.state.gender === 'female' ? chosen_char.name_f : chosen_char.name_m} {chosen_char.lastname}
                          <img className="speaking-level" src={chosen_char.img_speaking_level} />
                        </h4>
				    						<p className="attitude">{this.state.gender === 'female' ? Parser(chosen_char.attitude_f):Parser(chosen_char.attitude_m)}</p>
				    					</div>
				    				</div>
				    			}
				    		</div>
			    		))}
			    		</ul>
			    	</div>
			    </div>
			  </div>
        <div className="section section-light">
          <div className="content">
            <div className="instruction-box">
              <h2 className="h2 big centered notice-title no-spacing">Attention</h2>
              <span>Le choix de personnage est final. </span>
              <span>N'hésitez pas à vous coordonner avec les autres joueurs et joueuses pour que personne ne soit déçu de son personnage!</span>
            </div>
          </div>
        </div>
	    	<div className="section section-dark">
	    		<div className="content">
	    			<div className="bordered border2 join-player">
	    				{this.state.formValid &&
	    					<React.Fragment>
	    						<p className="username">{this.state.username}</p>
	    						<p className="play_as">dans la peau de</p>
	    						<p className="char_name" style={{color: `#${chosen_char.color_hex}`}}>{this.state.gender === 'female' ? chosen_char.name_f:chosen_char.name_m} {chosen_char.lastname}</p>
	    					</React.Fragment>
	    				}
				    		
				    	{this.state.error != ''? <p className="error char-error centered">{this.state.error}</p> : '' }
				    	<input
				    		type='submit'
				    		value='Joindre la partie'
				    		className="button submit"
				    		disabled={!this.state.formValid} 
                onClick={this.handleSubmit}
				    	/>
				    </div>
				  </div>
			  </div>
	    </form>
	    </React.Fragment>
    );
  }
}

JoinGameForm.propTypes = {
  characters: PropTypes.array,
  pickedChars: PropTypes.array,
  gamehash: PropTypes.string,
};

export default JoinGameForm
