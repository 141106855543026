import React from "react"
import PropTypes from "prop-types"
import Parser from 'html-react-parser';

class FaqCard extends React.Component {
	constructor(props) {
    super(props); 

    this.state = {
    	opened: false,
    };
  }

  handleClick = (e) => {
	  if(this.state.opened) {
	    this.setState({opened: false});
	  } else {
	    this.setState({opened: true});
	  }
  }

  render () {
    return (
      <React.Fragment>
      	<li className={`${this.state.opened ? 'opened':'closed'}`}>
					<button title="Voir la réponse" className="q" onClick={this.handleClick}>
						{Parser(this.props.q)}
						<span className="btn"><span className="arrow"></span>Voir la réponse</span>
					</button>	
					<span className="a"><p>{Parser(this.props.a)}</p></span>
				</li>
      </React.Fragment>
    );
  }
}

FaqCard.propTypes = {
  q: PropTypes.string,
  a: PropTypes.string,
};


export default FaqCard
