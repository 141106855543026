import React from "react"
import PropTypes from "prop-types"
import Parser from 'html-react-parser';

class DivSlider extends React.Component {
	constructor(props) {
    super(props); 

    this.cardsCount = this.props.cards.length;
    this.cardRefs = [];
    this.sliderRef = React.createRef();

    this.state = {
      index: 1,
      slideWidth: 0,
      slidesShowing: 1,
    }
  }

  moveIndex = (i) => {
    var newIndex = this.state.index + i;
    
    if(newIndex <= 0) {
      newIndex = 1;
    } else if(newIndex > this.cardsCount) {
      newIndex = this.cardsCount;
    }

    this.setState({index : newIndex});
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    let slideWidth = this.calculateSlideIncrement();
    let slidesShowing = this.calculateSlidesShowing(slideWidth);

    this.setState({slideWidth: slideWidth, slidesShowing: slidesShowing});
  }

  calculateSlidesShowing = (slideWidth) => {
    let slidesShowing = Math.floor(this.sliderRef.current.clientWidth/slideWidth);

    return slidesShowing;
  }

  calculateSlideIncrement = () => {
    let cardWidth = this.cardRefs[0].clientWidth+2; //+ border
    let slideWidth = cardWidth + 26; //+ margin

    return slideWidth;
  }

  render () {
    var slideToLeft = this.state.slideWidth * (this.state.index-1);

    return (
      <React.Fragment>
      	<div className="__divSlider" ref={this.sliderRef}>
      		<div className="slider-overlay">
            <button className="btn prec" 
                    onClick={() => this.moveIndex(-1)}
                    disabled={this.state.index == 1}
            >
              <span className="arrow"></span>Précédant
            </button>

            <button className="btn next" 
                    onClick={() => this.moveIndex(1)}
                    disabled={this.state.index >= this.cardsCount - this.state.slidesShowing+1}
            >
              <span className="arrow"></span>Suivant
            </button>
          </div>

      		<div className="slider" style={{transform: `translateX(-${slideToLeft}px)`}}>
		      	{this.props.cards.map((o, i) => (

		      	  <div className="card" key={i} ref={ref => this.cardRefs[i] = ref}>
		        		<span className="header">{o.header}</span>
		        		<p className="centered-el card-content">{Parser(o.content)}</p>
		        	</div>
		      	))}
	      	</div>
	      </div>
      </React.Fragment>
    );
  }
}

DivSlider.propTypes = {
	cards: PropTypes.array,
};

export default DivSlider
