import React from "react"
import PropTypes from "prop-types"
import axios from "axios"

class GameStateController extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
    	gameStateValid: false,
    	error: '',
    };

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.fetchData('next=1');
  }

  fetchData(params) {
  	axios.get(`/api/game/${this.props.gamehash}/validate_game_state?${params}`).then(res => {
	    if(res.status == 200) {
	      if(res.data == "valid") {
	      	this.setState({
	      		gameStateValid: true,
	      		error: '',
	      	});
	      } else {
	      	this.setState({
	      		gameStateValid: false,
	      		error: res.data,
	      	});
	      }
	    } else {
	    	console.log(`[Error] ${res.data} (${res.status})`);
	    }
		})
  }  

  componentDidMount() {
    this.fetchData('');
    var params = '';
    if(this.props.autoNext === true) {
      params += 'next=1&auto=1';
    }

    this.interval = setInterval(() => this.fetchData(params), 3000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render () {
    return (
      <React.Fragment>
      	<button 
      		type="button" 
          className="button"
      		disabled={!this.state.gameStateValid} 
      		onClick={this.handleClick}
        >
          {this.props.text}
      	</button>
      	{this.state.error &&
	        <p className="gm-error">
	          {this.state.error}
	        </p>
      	}
      </React.Fragment>
    );
  }
}

GameStateController.propTypes = {
  gamehash: PropTypes.string,
  text: PropTypes.string,
  autoNext: PropTypes.bool,
};

export default GameStateController
