import React from "react"
import PropTypes from "prop-types"
class ImageZoom extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
    	zoomed: false,
    }
  }

  handleClick = () => {
    var newZoom = !this.state.zoomed;
	  this.setState({zoomed: newZoom});

    if(newZoom) {
      document.body.classList.add('noscroll');
    } else {
      document.body.classList.remove('noscroll');
    }
  }

  render () {
    return (
      <React.Fragment>
        <div className={this.props.class}>
        	<div className="_image-zoom" onClick={this.handleClick}>
        		<img src={this.props.src} alt={this.props.alt} />
        		<button title="Agrandir l'image">Agrandir l'image</button>
        	</div> 
        </div>

      	{this.state.zoomed &&
      		<div className="_image-zoom-overlay" onClick={this.handleClick}>
      			<img src={this.props.src_large} alt={this.props.alt} />
      			<button title="Fermer" className="close-btn">&#10005;</button>
      		</div>
      	}
      </React.Fragment>
    );
  }
}

ImageZoom.propTypes = {
	src: PropTypes.string,
	src_large: PropTypes.string,
	alt: PropTypes.string,
	class: PropTypes.string,
}

export default ImageZoom
