import React from "react"
import PropTypes from "prop-types"
import Parser from 'html-react-parser';

class EventFlash extends React.Component {
	constructor(props) {
    super(props); 

    this.state = {
    	showText: false,
    	animation: false,
    	animationEnding: false,
    	animationDone: false,
    }
  }

  handleLoad = () => {
    let _this = this;
    setTimeout(function(){ 
      _this.setState({animation: true}); 
    }, 3000);

    setTimeout(function(){ 
      window.scrollTo(0, 0);
      _this.setState({animationEnding: true}); 
    }, 13000); //once all black, removing the img

    setTimeout(function(){ 
      window.scrollTo(0, 0);
      _this.setState({animationDone: true}); 
    }, 17900); //removed 100ms to make sure to avoid desync additonal black appearing
  }

  componentDidMount() {
    //if document not loader yet, add the listener
    if (document.readyState === "complete") {
      this.handleLoad();
    } else {
      window.addEventListener('load', this.handleLoad);
    }
  }

  render () {
    return (
      <React.Fragment>
	  		<div 
	  			className={`event-overlay ${this.state.animation ? 'going':''}`}
	  			style={this.state.animationDone ? {display: 'none'}:{}}
  			>

	  			<img 
	  				src={this.props.img}
	  				className={this.state.animationEnding ? 'hidden':''}
	  				/>
	  			<div className={`animation ${this.state.animation ? 'going':''}`}></div>
	  		</div>
      </React.Fragment>
    );
  }


}

EventFlash.propTypes = {
	img: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default EventFlash
