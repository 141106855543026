import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { Chart } from "react-google-charts";

class FunnyVote extends React.Component {
	constructor(props) {
    super(props); 

    this.state = {
    	vote: 0,
      voted: this.props.already_voted,
      chartW: 400,
    }

    this.chartWrapper;
    this.refResults = React.createRef();
  }

  changeHandler = (e) => {
    let vote = e.target.value;

    axios.get(`/api/game/${this.props.gamehash}/player_vote?vote=${vote}&kind=${this.props.kind}`).then(res => {
      if(res.status == 200) {       
        this.setState({voted: true, vote: vote});
      }
    }).catch(err => {
      console.log(err);
    })
  }

  playerById = (id) => {
    var player = false;
    this.props.players.forEach((ply) => {
      if (ply.id == id) {
        player = ply;
        return ply;
      }
    });

    return player;
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      chartW: this.refResults.current.clientWidth-90,
    });
  }

  render () {
    var total_vote = 0;
    let results = this.props.results;
    let _this = this;
    var piechart_slices = [['Joueur', 'Votes']];
    var slices_colors = {};
    
    let results_keys = Object.keys(results);
    if(results_keys.length != 0) {
      results_keys.forEach(function (k, i) {
        total_vote += results[k];
        var ply = _this.playerById(k);
        piechart_slices.push([ply.name, results[k]]);
        slices_colors[i] = {color: `#${ply.character_color}`}
      })
    }

    return (
      <div className="__funny_vote">
        <h3 className="h3 darkred vote-title" ref={this.refResults}>{this.props.title}</h3>
        <form 
          className={`vote-player vote-form ${this.state.voted == true ? 'hidden': ''}`}
          onSubmit={this.handleSubmit}
        >
      		{this.props.players.map((ply, i) => (
      			<label htmlFor={`${this.props.kind}-p${ply.id}`} key={i} className={this.state.vote == ply.id ? "selected":""}>
      				<input 
      					type="radio" 
      					id={`${this.props.kind}-p${ply.id}`} 
      					name="vote" 
      					value={ply.id}
                onChange={this.changeHandler}
    					/>
              <div className="img-container" style={{backgroundImage: `url(${ply.character_img_grey})`}}>
                <img src={ply.character_img} alt={ply.character} />
              </div>
              <span className="username">{ply.name}</span>
      			</label>
          ))}
        </form>

        <div className={`vote-results ${this.state.voted == true ? '': 'hidden'}`}>
          <div className={`chart-overlay ${total_vote >= this.props.votes_needed ? 'hidden':''}`}>
            <h3 className="h3 darkred vote-title">{this.props.title}</h3>
            <span>Tous les joueurs doivent voter</span>
          </div>
          <div className="chart-border">
            {this.state.voted &&
              <Chart
                key={this.state.chartW} //force redraw on change state
                width={this.state.chartW}
                height={this.state.chartW}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={piechart_slices}
                options={{
                  legend: 'none',
                  slices: slices_colors,
                  pieSliceText: 'label',
                  chartArea:{top:10, right: 10, bottom: 10, left: 10},
                  fontSize: 15,
                  fontName: 'PT Serif',
                }}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

FunnyVote.propTypes = {
  title: PropTypes.string,
  players: PropTypes.array,
  kind: PropTypes.string,
  gamehash: PropTypes.string,
  results: PropTypes.object,
  votes_needed: PropTypes.number,
  already_voted: PropTypes.bool,
};

export default FunnyVote
