import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import Parser from 'html-react-parser';

class MoreReviews extends React.Component {
	constructor(props) {
    super(props); 

    this.ids = this.props.ids;

    this.state = {
    	reviews: [],
    	show: false,
    	disabled: false,
    }
  }

  handleClick = (e) => {
  	this.setState({disabled: true});

		axios.get(`/api/reviews?game=${this.props.game}&limit=${this.props.limit}&ids=${this.ids.join(',')}`).then(res => {
		  if(res.status == 200) {     
		  	var reviews = res.data;
		  	if(reviews.length > 0) {
		  		//store the new ids, to avoid duplicate reviess on next query
		  		this.ids = this.ids.concat(reviews.map(r => r.id)); 

		  		var show = true;
		  		if(reviews.length < this.props.limit) {
		  			show = false;
		  		}
		  		this.setState({reviews: this.state.reviews.concat(reviews), show: show, disabled: false});	

		  	} else {
		  		this.setState({show: false});
		  	}
		  }
		}).catch(err => {
		  console.log(err);
		})
  }

  componentDidMount() {
  	this.setState({show: true});
  }

  render () {
    return (
      <React.Fragment>
      	{this.state.reviews.map((r, i) => (
      		<div className={`review entering ${(this.props.ids.length+i+1)% 2 != 0 ? 'left':'right'}`} key={i}>
      			<div className="score centered-el">
              {Parser(r.stars)}
            </div>
      			<p className="centered-el">{r.desc}</p>

      			<p className="by">{r.by}</p>
      		</div>
      	))}
      	
      	{this.state.show &&
      		<p className="centered-el">
      			<button className="link bigger" 
      			onClick={this.handleClick}
      			disabled={this.state.disabled}>
      				Voir plus
      			</button>
    			</p>
      	}
      </React.Fragment>
    );
  }
}

MoreReviews.propTypes = {
  ids: PropTypes.array,
  game: PropTypes.string,
  limit: PropTypes.number,
};


export default MoreReviews
