import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import consumer from "./../../channels/consumer"
import InvitePlayer from "./../InvitePlayer";

class PlayerListLobby extends React.Component {
	constructor(props) {
    super(props);

    // those should match the css breaks at all time ! 
    this.two_col_break = 400;
    this.three_col_break = 650;

    this.state = {
      screenW: document.documentElement.clientWidth,
      username_by_char: {},
      player_by_char: {},
    };
  }

  handleKick = (event) => {
    let val = event.target.value;
    
    axios.get(`/api/game/${this.props.gamehash}/player_kick?player=${val}`).then(res => {
      if(res.status == 200) {       
      } else {
        console.log(`[Error] ${res.data} (${res.status})`);
      }
    })
  }

  setStatePlayerFromData = (_players) => {
    var username_by_char = {};
    var player_by_char = {}
    
    _players.forEach(function(ply) {
      username_by_char[ply.character_id] = ply.username;
      player_by_char[ply.character_id] = ply.id;
    });

    this.setState({
      username_by_char: username_by_char,
      player_by_char: player_by_char,
    });
  }

  updateWindowDimensions = () => {
    this.setState({screenW: document.documentElement.clientWidth});
  }

  fetchData() {
  	axios.get('/api/game/'+this.props.gamehash+'/players_list').then(res => {
	    if(res.status == 200) {
	      this.setStatePlayerFromData(res.data);
	    } else {
	    	console.log(`[Error] ${res.data} (${res.status})`);
	    }
		})
  }

  componentDidMount() {
		this.fetchData();

    let thisComponent = this;
    consumer.subscriptions.create({channel: 'PlayerslistChannel', game: this.props.gamehash}, {
      received(data) {
        let jData = JSON.parse(data);
        thisComponent.setStatePlayerFromData(jData);
      }
    });

    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  render () {
    var nb_per_row = 1;

    if(this.state.screenW >= this.three_col_break) {
      nb_per_row = 3;
    } else if (this.state.screenW >= this.two_col_break) {
      nb_per_row = 2;
    }

  	return (
      <React.Fragment>
        <ul className="characters-display clearfix">
          {this.props.characters.map((char, i) => (
            <React.Fragment  key={i}> 
              <li>
                {char.id in this.state.username_by_char ? (
                  <React.Fragment>  
                    <img alt={char.lastname} src={`${char.img_src}`} className="char-img" />
                    <p className="username" style={{color: `#${char.color_hex}`}}>
                      {this.state.username_by_char[char.id]}
                      {this.props.playerGm != null && this.state.player_by_char[char.id] != this.props.playerGm &&
                        <button 
                          type="button" 
                          value={this.state.player_by_char[char.id]}
                          className='link error kick-btn'
                          onClick={this.handleKick}
                          onClick={(e) => {  
                            if(window.confirm(`Retirer ${this.state.username_by_char[char.id]}?`)) {
                              this.handleKick(e);
                            }
                          }}
                        >
                          (Retirer)
                        </button>
                      }
                    </p>
                  </React.Fragment>
                ) : (
                  <img alt={char.lastname} src={`${char.img_grey_src}`} className="char-img" />
                )}
              </li>
            
              {(i !== 0 && ((i+1) % nb_per_row == 0)) &&
                <div className="row-end"></div>
              }

            </React.Fragment>
          ))}
        </ul>
        {(Object.keys(this.state.player_by_char).length != this.props.maxNbPlayers && this.props.isGm) &&
          <div className="lobby-inv">
            <h2 className="h2 big centered">Invitez plus de joueurs!</h2>
            <InvitePlayer 
              gamehash={this.props.gamehash}
              invLink={this.props.invLink}
            />
          </div>
        }
      </React.Fragment>
    );
  }
}

PlayerListLobby.propTypes = {
  gamehash: PropTypes.string,
  characters: PropTypes.array,
  playerGm: PropTypes.number,
  maxNbPlayers: PropTypes.number,
  invLink: PropTypes.string,
  isGm: PropTypes.bool,
};

export default PlayerListLobby
