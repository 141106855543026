import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import Checkbox from "./../utilities/Checkbox"

class VoteToReturn extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
      current_vote: this.props.vote,
    };
  }

  changeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;

    if(nam == 'return') {  
      this.playerVoteReturn(event.target.checked);
    }
  }

  playerVoteReturn(vote) {
    axios.get(`/api/game/${this.props.gamehash}/players_list?return=${vote}`).then(res => {
      if(res.status == 200) {
        this.setState({current_vote: vote});
      }
    }).catch( err => {
      console.log(`[Error] ${err}`);
    })
  }

  render () {
    return (
      <React.Fragment>
    		<form className="form">
    	    <label htmlFor="return" className="label__checkbox"> 
    	      <Checkbox 
    	        type="checkbox" 
    	        id="return" 
    	        name="return" 
    	        value="return" 
    	        checked={this.state.current_vote} 
    	        onChange={this.changeHandler}
    	      />
    	      Revenir
    	    </label>
    	  </form>
      </React.Fragment>
    );
  }
}

VoteToReturn.propTypes = {
  gamehash: PropTypes.string,
  vote: PropTypes.bool
};

export default VoteToReturn
