import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import Checkbox from "./../utilities/Checkbox"
import Parser from 'html-react-parser';

class BoxedInteraction extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
    	checked: this.props.checked,
    };
  }  

  changeHandler = (event) => {
    let val = event.target.value;
    let param = '';

    var checked = event.target.checked;

    if(checked) {
      param = 'add';
    } else {
      param = 'remove';
    }

    axios.get(`/api/game/${this.props.gamehash}/interaction?${param}=${val}`).then(res => {
      if(res.status == 200) {
        this.setState({checked: checked});
      }
    }).catch( err => {
      console.log(`[Error] ${err}`);
    })
  }

  render () {
  	let id = this.props.interaction.id;
  	if(this.props.interaction.required) {
	  		return (
	      <React.Fragment>
	      	<form>
            <span className="tell">
  	      		<label htmlFor={`interaction${id}`} className="label__checkbox">
  	      		  <Checkbox 
                  checked={this.state.checked}
                  id={`interaction${id}`}
                  name={`interaction${id}`}
                  value={`${id}`} 
                  onChange={this.changeHandler}
                />
  	      		  <span className="label-box">{Parser(this.props.interaction.text)}</span>
  	      		</label>
            </span>
	      	</form>   
	      </React.Fragment>
	    );
	  } else {
	  	return (
        <span className="react">
          <span className="inline-icon icon-dia"></span>
          <span className="label-box">{Parser(this.props.interaction.text)}</span>
        </span>
      );
	  }
  }
}

BoxedInteraction.propTypes = {
	interaction: PropTypes.object,  
	gamehash: PropTypes.string,
	checked: PropTypes.bool,
};

export default BoxedInteraction

// id={`interaction${id}`}
// name={`interaction${id}`}
// value={`${id}`}     
// onChange={this.changeHandler}
