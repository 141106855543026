import React from "react"
import PropTypes from "prop-types"
import axios from "axios"

class GameTimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.form_error = '';

    this.state = {
      gametime: this.props.current_gametime,
      formValid: false,
      error: '',
    };

    this.dateFormat = {
      day: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      month: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
    }
  }

  changeHandler = (event) => {
    let val = event.target.value;

    var pickedDate = new Date(val)
    var now = new Date();
    
    if (now < pickedDate) {
      axios.get(`/api/game/${this.props.gamehash}/gametime?t=${val}`).then(res => {
        if(res.status == 200) {       
          this.setState({gametime: val, formValid: true, error: ''});
        } else {
          this.setState({formValid: false, error: 'Date invalide.'});
        }
      })
    } else {
      this.setState({formValid: false, error: 'La date choisie doit être dans le futur.'});
    }
  }

  render () {
    if(this.state.gametime != false) {
      var gametime = new Date(this.state.gametime);
    } else {
      var gametime = new Date();
    }


    return (
      <React.Fragment>
        <form className="form gametime-picker" onSubmit={e => e.preventDefault()}>
          <div className="datetime-display">
            <span className="month">{this.dateFormat.month[gametime.getMonth()]}</span>
            <span className="day-n">{gametime.getDate()}</span>
            <span className="day">{this.dateFormat.day[gametime.getDay()]}</span>
          </div>

          <div className="field">
            <label htmlFor="gametime">Indiquez un temps pour la partie</label>
            {this.state.error != ''? <p className="error char-error centered">{this.state.error}</p> : '' }
            <input 
              type="datetime-local" 
              id="gametime" 
              name="gametime" 
              value={this.state.gametime}
              onChange={this.changeHandler}
            />
          </div>
        </form>
      </React.Fragment>
    );
  }
}

GameTimePicker.propTypes = {
  gamehash: PropTypes.string,
};


export default GameTimePicker
