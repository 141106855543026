import React from "react"
import PropTypes from "prop-types"
import Parser from 'html-react-parser';

class MainMenu extends React.Component {
	constructor(props) {
    super(props); 

    this.state = {
    	opened: false,
    };
  }

  handleClick = (event) => {
    let action = event.target.getAttribute("action");

    switch(action) {
      case "toggle":
        if(this.state.opened) {
          document.body.classList.remove('noscroll');
          this.setState({opened: false});  
        } else {
          document.body.classList.add('noscroll');
          this.setState({opened: true});  
        }
        break;
      case "close":
        if(this.state.opened) {
          document.body.classList.remove('noscroll');
          this.setState({opened: false}); 
        }
        break;
    }
  }

  handleLinkClicked = (e) => {
    // handle same page link to close the menu
    document.body.classList.remove('noscroll');
    this.setState({opened: false});
  }

  render () {
    return (
      <React.Fragment>
        <div className={`off-menu ${this.state.opened == true ? '':'hidden'}`}onClick={this.handleClick} action="close"></div>
      	<button title="Menu" id="menu" onClick={this.handleClick} action="toggle">
            <div className="line line1"></div>
            <div className="line line2"></div>
            <div className="line line3"></div>
            Menu
        </button>
        <aside className={`menu ${this.state.opened == true ? 'opened':'closed'}`}>
          <button 
            title="Fermer" 
            className="close-btn" 
            onClick={this.handleClick} action="close">&#10005;
          </button>
        	<a className="menu-logo" title="Accueil" href={this.props.index}></a>
	        <ul className="user-menu"> 
	        	{this.props.items.map((item, i) => (
	        		<li key={i} onClick={this.handleLinkClicked}>{Parser(item)}</li>
	          ))}
            <li className="socials">
              <ul>
                <li><a className="facebook" title="Facebook" target="_blank" href="https://www.facebook.com/CabinetMysteriis/">Facebook</a></li>
                <li><a className="instagram" title="Instagram" target="_blank" href="https://www.instagram.com/cabinetmysteriis/">Instagram</a></li>
              </ul>
            </li>
            <li><a href="mailto:info@mysteriis.com" className="mailto">info@mysteriis.com</a></li>
	        </ul>
	      </aside>
      </React.Fragment>
    );
  }
}

MainMenu.propTypes = {
  items: PropTypes.array,
  index: PropTypes.string,
};

export default MainMenu
