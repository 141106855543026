import React from "react"
import PropTypes from "prop-types"
import Parser from 'html-react-parser';

class ShowInvestigationToBtn extends React.Component {
	constructor(props) {
  	super(props);

    this.state = {
      sent: false,
    };
  }

  handleClick = (event) => {
  	this.props.showInvestigationTo(this.props.investigation, this.props.character);
    this.setState({sent: true});

    setTimeout(() => {
      this.setState({sent: false});
    }, 3000);
  }

  render () {
    return (
      <React.Fragment>
        <div className={`show-to-btn ${this.state.sent?'sent':''}`}>
          <div className="overlay"></div>
          <button 
            onClick={this.handleClick}
            disabled={this.state.sent}
          >
            <span className="inline-icon icon-eye bigger2"></span>
            {Parser(this.props.label)}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

ShowInvestigationToBtn.propTypes = {
	showInvestigationTo: PropTypes.func,
	investigation: PropTypes.number,
	character: PropTypes.number,
	label: PropTypes.string,
};

export default ShowInvestigationToBtn
