import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import consumer from "./../../channels/consumer"
import Checkbox from "./../utilities/Checkbox"

class PlayersReady extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
      current_ready: this.props.ready,
    };
  }

  changeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;

    if(nam == 'ready') {  
      this.playerReady(event.target.checked);
    }
  }

  playerReady(ready) {
    axios.get(`/api/game/${this.props.gamehash}/players_list?ready=${ready}`).then(res => {
      if(res.status == 200) {
        this.setState({current_ready: ready});
      }
    }).catch( err => {
      console.log(`[Error] ${err}`);
    })
  }

  render () {
    return (
      <React.Fragment>
      	<form className="form">
          <label htmlFor="ready" className="label__checkbox"> 
            <Checkbox 
              type="checkbox" 
              id="ready" 
              name="ready" 
              value="ready" 
              checked={this.state.current_ready} 
              onChange={this.changeHandler}
            />
            Je suis prêt.e !
          </label>
        </form>
      </React.Fragment>
    );
  }
}

PlayersReady.propTypes = {
  gamehash: PropTypes.string,
  ready: PropTypes.bool
};

export default PlayersReady
