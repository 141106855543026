import React from "react"
import PropTypes from "prop-types"
import axios from "axios"

class PlayerVote extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
    	vote: this.props.currentVote,
    };
  }

  changeHandler = (e) => {
    let val = e.target.value;
    axios.get(`/api/game/${this.props.gamehash}/player_vote?vote=${val}&kind=guilty`).then(res => {
      if(res.status == 200) {       
        this.setState({vote: val});
      } else {
        console.log(`[Error] ${res.data} (${res.status})`);
      }
    })
  }

  render () {
    return (
      <React.Fragment>
      	<form className="vote-form">
          <ul className="characters-display clearfix vote-form-players">
            {this.props.playerChars.map((char, i) => (
              <li 
                key={i} 
                className={this.state.vote == char.player_id ? "selected" : ""}
              >
                <label htmlFor={char.character_id} key={i}>
                  <input 
                    type="radio" 
                    id={char.character_id} 
                    name="vote" 
                    value={char.player_id}
                    checked={this.state.vote == char.player_id} 
                    onChange={this.changeHandler}
                  />
                  <div className="char-img-container clearfix" style={{backgroundImage: `url(${char.img_src_grey})`}}>
                    <img alt={char.lastname} src={`${char.img_src}`} className="char-img" />
                  </div>
                  <p className="username" style={{color: `#${char.color_hex}`}}>{char.player}</p>

                </label>
              </li>
            ))}
          </ul>
      	</form>
      </React.Fragment>
    );
  }
}

PlayerVote.propTypes = {
  gamehash: PropTypes.string,
  playerChars: PropTypes.array,
  currentVote: PropTypes.number,
};

export default PlayerVote
